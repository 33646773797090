import { template as template_99e52581c73c4e389efa55e176a5ce4f } from "@ember/template-compiler";
const SidebarSectionMessage = template_99e52581c73c4e389efa55e176a5ce4f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
