import { template as template_1002493e24d0426089fe664ee9b3fbdc } from "@ember/template-compiler";
const FKLabel = template_1002493e24d0426089fe664ee9b3fbdc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
